import React from "react";
import { PropagateLoader } from "react-spinners";
import style from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={style.Container}>
      <div className={style.Loader}>
        <PropagateLoader
          color={"#1655b0"}
          loading={true}
        />
      </div>
    </div>
  );
};

export default Loader;
