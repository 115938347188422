import React, { useState, useEffect } from "react";
import style from "./Sidebar.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import { PiUsersThreeFill } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../action/User";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import icon from "../../assets/1-page.png";
import { TbLayoutDashboard } from "react-icons/tb";
import { SiGoogleanalytics } from "react-icons/si";
import logo from "../../assets/logo-placeholder.jpg"
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profileData);
  console.log(profile);
  const UserName = localStorage.getItem("UserName")
  const orgname=localStorage.getItem("Orgname")
  const orgimage=localStorage.getItem("Orgimage")

  const [logout, setLogout] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleLogout = (e) => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("adminStatus");
    localStorage.removeItem("selectedCategory");
    localStorage.removeItem("user")
    localStorage.removeItem("Orgname")
    localStorage.removeItem("Orgimage")
    navigate("/login");
    window.location.reload();
  };

  const handleCategorySelection = (category) => {
    navigate(`/${category}`);
    setSelectedCategory(category);
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/organization") setSelectedCategory("organization");
    else if (path === "/user") setSelectedCategory("user");
    else if (path === "/voucher") setSelectedCategory("voucher");
    else if (path === "/voucher/upload") setSelectedCategory("voucher");
    else if (path === "/bulk") setSelectedCategory("bulk");
    else if (path === "/bulk/upload") setSelectedCategory("bulk");
    else if (path === "/bulk/upload/section") setSelectedCategory("bulk");
    else if (path === "/analytics") setSelectedCategory("analytics");

  }, [location]);

  // useEffect(() => {
  //   const storedCategory = localStorage.getItem("selectedCategory");
  //   if (storedCategory) {
  //     setSelectedCategory(storedCategory);
  //   }
  // }, []);

  // useEffect(() => {
  //   dispatch(UserProfile());
  // }, []);

  return (
    <div className={style.Sidebar}>
      <div className={style.Header}>
        <div className={style.headerImage}>
          <img src={orgimage?orgimage:logo} alt="notfound" />
        </div>
      </div>
      <div className={style.adminName}>
        <p> {orgname}</p>
      </div>
      <div className={style.Menu}>
        <div
          className={
            selectedCategory === ""
              ? style.selected
              : style.menuIcons
          }
          onClick={() => handleCategorySelection("")}
        >
          <TbLayoutDashboard />
          <p>Dashboard</p>
        </div>
        <div
          className={
            selectedCategory === "voucher" ? style.selected : style.menuIcons
          }
          onClick={() => {
            handleCategorySelection("voucher");
          }}
        >
          <IoCloudUploadOutline />
          <p>Voucher Upload</p>
        </div>
        <div
          className={
            selectedCategory === "bulk" ? style.selected : style.menuIcons
          }
          onClick={() => {
            handleCategorySelection("bulk");
          }}
        >
          <MdOutlineDriveFolderUpload />
          <p>Bulk Upload</p>
        </div>

        <div
          className={
            selectedCategory === "analytics" ? style.selected : style.menuIcons
          }
          onClick={() => {
            handleCategorySelection("analytics");
          }}
        >
          <SiGoogleanalytics />
          <p>Analytics</p>
        </div>

        <div
          className={style.menuIcons}
          onClick={() => {
            setLogout(true);
          }}
        >
          <BiLogOutCircle />
          <p>Logout</p>
        </div>
      </div>
      <div className={style.Footer}>
        <div className={style.Footertext}>
          <p>Powered By</p>
        </div>
        <div className={style.FooterImage}>
          <img src={icon} alt="" />
        </div>
      </div>
      {logout ? (
        <>
          <div
            className={style.logoutOverlay}
            onClick={() => {
              setLogout(false);
            }}
          ></div>
          <div className={style.Popup}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setLogout(false);
                }}
              />
            </div>
            <div className={style.popupSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to logout?</p>
              </div>
              <div className={style.PopupButton}>
                <button
                className={style.YesButton}
                  onClick={(e) => {
                    handleLogout();
                  }}
                >
                  YES
                </button>
                <button
                className={style.NOButton}
                  onClick={() => {
                    setLogout(false);
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default Sidebar;
