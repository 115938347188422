import React from "react";
import style from "./BulkUpload.module.css";
import { MdFolder } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const BulkUpload = () => {
  const navigate = useNavigate();

  const selectionList = [
    { name: "Sale Invoice", type: "sale_invoice" },
    { name: "Purchase Invoice", type: "purchase_invoice" },
    { name: "Payment", type: "expense" },
    { name: " Receipt", type: "payment" },
    { name: "Bank Statement", type: "bank_statement" },
    
  ];

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <h3>Please select your voucher type</h3>
      </div>
      <div className={style.typeList}>
        <div className={style.types}>
          <div className={style.typeGrid}>
            {selectionList &&
              selectionList.length > 0 &&
              selectionList.map((list, i) => {
                return (
                  <Tooltip title={list.name} arrow key={i}>
                    <div
                      className={style.card}
                      onClick={() => {
                        navigate("/bulk/upload", {
                          state: { type: list.type, name: list.name },
                        });
                      }}
                    >
                      <MdFolder />
                      <p>{list.name}</p>
                    </div>
                  </Tooltip>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
