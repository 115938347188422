import React,{useEffect,useState} from 'react'
import style from './Home.module.css'
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";

const Home = () => {

const navigate=useNavigate()

 const user=localStorage.getItem("user")
 const roomId = localStorage.getItem("RoomId");

const[countDetails,setCountDetails]=useState([])

  useEffect(()=>{
      if(user===null||user===undefined){
        navigate("/login")
      }
  },[user])

  async function getCounts() {
    await fetch(`${baseUrl}/dashboard/mobile`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCountDetails(data.data)
        }
      });
  }
  useEffect(()=>{
    getCounts()
  },[roomId])


  return (
    <div className={style.Container}>
    <div className={style.HomeContent}>
      <div className={style.Grid}>
        {countDetails&&countDetails.length>0?countDetails.map((count,i)=>{
          return(
            <div key={i} className={style.Card}>
          <div style={{backgroundColor:count.name === "Error"?"#d23e3e":''}} className={style.CardHead}>
            <p>{count.name === "purchase_invoice" && "Purchase Invoice"}
               {count.name=== "sale_invoice" && "Sale Invoice"}
               {count.name === "bank_statement" && "Bank Statement"}
               {count.name === "payment" && "Payment Voucher"}
               {count.name === "expense" && "Expense Voucher"}
               {count.name === "Error" && "Error"}
               </p>
          </div>
          <div onClick={()=>{
            navigate("/voucher",{
              state:{
                type:count.name
              }
            })
          }} className={style.CardBody}>
            <p>{count.count}</p>
            <span>Documents</span>
          </div>
        </div>
          )
        }):'No items Uploaded...'}
        
        
      </div>
    </div>

    </div>
  )
}

export default Home