import React, { useRef, useState } from "react";
import style from "./UploadContent.module.css";
import { TbFileUpload } from "react-icons/tb";
import { RiDragDropLine } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { RiChatUploadLine } from "react-icons/ri";
import sale from "../../assets/sale invoice.png";
import purchase from "../../assets/purchase-order.png";
import expence from "../../assets/expenses.png";
import bank from "../../assets/bank-statement.png";
import payement from "../../assets/payment voucher.png";
import images from "../../assets/gallery_icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../Url";
import { UserProfile } from "../../action/User";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { GrDocumentPdf } from "react-icons/gr";
import { FaFileImage } from "react-icons/fa";

const UploadContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const organizationId = localStorage.getItem("OrgId");
  const roomId=localStorage.getItem("RoomId")


  const { profile } = useSelector((state) => state.profileData);
  console.log(profile);

  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState({ preview: "", data: "" });
  const [fileSelected, setFileSelected] = useState(false);
  const [dragUploadSection, setDragUploadSection] = useState(false);
  const [documentSelection, setDocumentSelection] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocument,setSelectedDocument]=useState("")
  const[documentType,setDocumentType]=useState(false)
  const[selectedDocumentType,setSelectedDocumentType]=useState('')
  const[selectedPDF,setSelectedPDF]=useState()

  const selectionList = [
    { image: sale, name: "Sale Invoice", type: "sale_invoice" },
    { image: purchase, name: "Purchase Invoice", type: "purchase_invoice" },
    { image: expence, name: "Payment", type: "expense" },
    { image: payement, name: "Receipt", type: "payment" },
    { image: bank, name: "Bank Statement", type: "bank_statement" },
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const allowedImageTypes = ["image/png", "image/jpeg"];
  const allowedPDFTypes = ["application/pdf"];
  if (selectedDocumentType === "Image" && allowedImageTypes.includes(file.type)) {
    const img = {
      preview: URL.createObjectURL(file),
      data: file,
    };
    console.log("Uploaded image:", img);
    setSelectedImage(img);
    setFileSelected(true);
  } else if (selectedDocumentType === "PDF" && allowedPDFTypes.includes(file.type)) {
    setSelectedPDF(file);
    console.log("Uploaded PDF:", file);
    setFileSelected(true);
  } else {
    toast.error("Invalid file type for the selected document type");
    
  }

  };
  
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {};

  const handleDrop = (e) => {
    e.preventDefault();
    if (!fileSelected) {
      const droppedFiles = Array.from(e.dataTransfer.files);
    const file = droppedFiles[0];

    if (selectedDocumentType === "Image" && file.type.includes("image/")) {
      const image = {
        preview: URL.createObjectURL(file),
        data: file,
      };
      setSelectedImage(image);
      setFiles([...files, ...droppedFiles]);
      setDragging(true);
      setDragUploadSection(true);
    } else if (selectedDocumentType === "PDF" && file.type === "application/pdf") {
      setSelectedPDF(file);
      setFiles([...files, ...droppedFiles]);
      setDragging(true);
      setDragUploadSection(true);
    } else {
      toast.error("Invalid file type for the selected document type");
      setDragging(false);
    }
    }
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setDragUploadSection(false);
      setDragging(false);
      if(selectedDocumentType === "Image"){
      setSelectedImage(null);
      }
      else if(selectedDocumentType === "PDF"){
        setSelectedDocument(null)
      }
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };

  async function FileUpload() {
    const formData = new FormData();
    if (selectedImage) {
      formData.append("image", selectedImage.data);
    } else if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`image${index}`, file);
      });
    }
    formData.append("title", selectedDocument);
    formData.append("room_id",roomId);
    formData.append("org_id", organizationId);
    formData.append("type", "web");

    await fetch(`${baseUrl}/scan/invoices/single/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setDocumentSelection(false);
          toast.success("file uploaded successfully");
          setFileSelected(false);
          setSelectedImage(null);
          setFiles([]);
          setDragUploadSection(false);
          setDragging(false);
          setIsLoading(false)
          setTimeout(() => {
            navigate("/voucher");
          }, 2000);
        }
      });
  }

  useEffect(() => {
    dispatch(UserProfile());
  }, []);

  return (
    <div className={style.Container}>
      <ToastContainer />
      <div className={style.BackButton}>
          <button onClick={()=>{
            navigate("/voucher");
          }}>Back to voucher</button>
        </div>
      {isLoading?<div className={style.LoaderDiv}><Loader/></div>:''}
      <div className={style.uploadContent}>
        <div
          className={style.dragDrop}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className={style.fileHead}>
            {dragUploadSection ? (
              ""
            ) : (
              <div className={style.fileText}>
                {dragging ? (
                  ""
                ) : (
                  <div className={style.fileView}>
                    <button onClick={handleClick}>Select File</button>
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      accept={selectedDocumentType === "Image" ? "image/png, image/jpeg" : "application/pdf"}
                      style={{ display: "none" }}
                    />
                    {selectedImage ? <p>{selectedImage.data.name}</p> : ""}
                    {selectedPDF ? <p>{selectedPDF.name}</p> : ""}
                  </div>
                )}

                {fileSelected || dragging ? (
                  ""
                ) : (
                  <>
                    <span>Or</span>
                    <p>Drag and drop files here</p>
                  </>
                )}
              </div>
            )}

            <div className={style.dragContent}>
              {files.map((file, index) => (
                <div key={index} className={style.fileitem}>
                  <p>{truncateText(file.name, 25)}</p>
                  <AiOutlineCloseCircle
                    onClick={() => handleFileRemove(index)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={style.fileButton}>
            <button
              onClick={() => {
                if (fileSelected || dragging) {
                  // setDocumentSelection(true);
                  FileUpload()
                  setIsLoading(true)
                } else {
                  toast.error("No file is selected");
                }
              }}
            >
              <RiChatUploadLine />
              Upload
            </button>
          </div>
        </div>
      </div>

      {documentSelection ? (
        <>
          <div
            onClick={() => {
              // setDocumentSelection(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.docSelection}>
            <div className={style.docGrid}>
              {selectionList &&
                selectionList.length > 0 &&
                selectionList.map((list, index) => {
                  const backgroundColorMap = {
                    sale_invoice: "#9c5ca6",
                    purchase_invoice: "#e85d72",
                    expense: "#ea913f",
                    bank_statement: "#4cb050",
                    payment: "#7aa6cb",
                  };
                  return (
                    <div className={style.invoice}>
                      <div
                        className={style.invoiceImage}
                        style={{
                          backgroundColor: backgroundColorMap[list.type],
                        }}
                        onClick={() => {
                          // FileUpload(list.type);
                          setDocumentSelection(false)
                          setSelectedDocument(list.type)
                          setDocumentType(true)
                        }}
                      >
                        <img src={list.image} alt="" />
                      </div>
                      <div className={style.invoiceName}>
                        <p>{list.name}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {documentType?
      <>
      <div
            onClick={() => {
              // setDocumentSelection(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.docSelection}>
            <div className={style.DocumentTypeSection}>
             <div className={style.DocumentTypeHead}> <p>Select file type</p></div>
            <div className={style.DocumentTypeGrid}>
              <div onClick={()=>{
                setSelectedDocumentType("Image")
                setDocumentType(false)
              }} className={style.invoiceContainer}>
                        <div
                          className={style.invoiceIcon}
                        >
                         <FaFileImage/>
                        </div>
                        <div className={style.invoiceName}>
                          <p>Image</p>
                        </div>
                      </div>
                      <div onClick={()=>{
                setSelectedDocumentType("PDF")
                setDocumentType(false)
              }}  className={style.invoiceContainer}>
                        <div
                          className={style.invoiceIcon}
                        >
                         <GrDocumentPdf/>
                        </div>
                        <div className={style.invoiceName}>
                          <p>PDF</p>
                        </div>
                      </div>
            </div>
            </div>
          </div>
      </>
      :
      ''}
    </div>
  );
};

export default UploadContent;
