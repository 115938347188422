import React from 'react'
import style from './Analytics.module.css'
const Analytics = () => {
  return (
    <div className={style.Container}>
        <div className={style.Content}>
            <iframe src='https://app.powerbi.com/view?r=eyJrIjoiNTIzYWYyNWUtNzk0MS00ZWY1LWFjOTEtYjZmZGRjMjNkZTM3IiwidCI6ImZhZjY1NDQ2LTViZDktNDg3OS1iMzlkLWI3NjEyMjVjMWE2YiIsImMiOjl9'></iframe>
        </div>
    </div>
  )
}

export default Analytics