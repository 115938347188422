import React, { useEffect, useState } from "react";
import style from "./BulkUploadSection.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../action/User";
import { baseUrl } from "../Url";
import { RiChatUploadLine } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { IoCloudUploadOutline } from "react-icons/io5";
import { GrDocumentPdf } from "react-icons/gr";
import { FaFileImage } from "react-icons/fa";

const BulkUploadSection = () => {
  const organizationId = localStorage.getItem("OrgId");
  const roomId = localStorage.getItem("RoomId");

  const { profile } = useSelector((state) => state.profileData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const invoiceType = state.type;

  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [fileStatus, setFileStatus] = useState(false);
  const [fileLength, setFileLength] = useState("");
  const[documentType,setDocumentType]=useState(false)
  const[selectedDocumentType,setSelectedDocumentType]=useState('')
  const[selectedPDF,setSelectedPDF]=useState()

  const truncateText = (text, maxLength) => {
    if (text && text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {};

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const images = droppedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
      data: file,
      name: file.name,
    }));
    setFiles([...files, ...images]);
    setDragging(true);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    setFileLength(updatedFiles.length);
    if (updatedFiles.length === 0) {
      setDragging(false);
    }
  };

  async function FileUpload() {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append("image", file.data);
    });
    formData.append("title", invoiceType);
    formData.append("room_id", roomId);
    formData.append("org_id", organizationId);

    await fetch(`${baseUrl}/scan/invoices`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status == true) {
          setFileStatus(false);
          toast.success("file uploaded successfully");
          setDragging(false);
          setFiles([]);
          setTimeout(() => {
            navigate("/bulk/upload", {
              state: { type: data.data[0].voucher_type },
            });
          }, 2000);
        }
      });
  }

  useEffect(() => {
    // dispatch(UserProfile());
  }, []);

  return (
    <div className={style.Container}>
      <ToastContainer />
      <div className={style.BackButton}>
          <button onClick={()=>{
            navigate("/bulk/upload",{
              state: { type:invoiceType },
            });
          }}>Back to voucher</button>
        </div>
      <div className={style.upload}>
        <div className={style.uploadContent}>
          <div className={style.header}>
            <h3>Upload your files </h3>
          </div>

          <div
            className={style.dragDrop}
            onDragEnter={handleDragEnter}
            onDragOver={(e) => e.preventDefault()}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {dragging ? (
              <div className={style.dragContent}>
                {files.map((file, index) => (
                  <div key={index} className={style.fileitem}>
                    <p>{file.name && truncateText(file.name, 25)}</p>
                    <AiOutlineCloseCircle
                      onClick={() => handleFileRemove(index)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={style.fileText}>
                <p>Drag and drop files here</p>
              </div>
            )}
            {dragging ? (
              <div className={style.fileButton}>
                <button
                  onClick={() => {
                    FileUpload();
                    setFileLength(files.length);
                    setFileStatus(true);
                  }}
                >
                  <RiChatUploadLine />
                  Upload
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {fileStatus ? (
          <div className={style.uploadStatus}>
            <div className={style.statusHead}>
              <IoCloudUploadOutline />
              <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                {/* <LinearProgress color="success" sx={{ height: "5px" }} /> */}
                <LinearProgress color="inherit" />
              </Stack>
            </div>
            <div className={style.statusBottom}>
              {fileLength > 1 ? (
                <h4>
                  <span>{fileLength}</span> files are Uploading
                </h4>
              ) : (
                <h4>Your file is Uploading</h4>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        
      </div>
      {documentType?
      <>
      <div
            onClick={() => {
              // setDocumentSelection(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.docSelection}>
            <div className={style.DocumentTypeSection}>
             <div className={style.DocumentTypeHead}> <p>Select file type</p></div>
            <div className={style.DocumentTypeGrid}>
              <div onClick={()=>{
                setSelectedDocumentType("Image")
                setDocumentType(false)
              }} className={style.invoiceContainer}>
                        <div
                          className={style.invoiceIcon}
                        >
                         <FaFileImage/>
                        </div>
                        <div className={style.invoiceName}>
                          <p>Image</p>
                        </div>
                      </div>
                      <div onClick={()=>{
                setSelectedDocumentType("PDF")
                setDocumentType(false)
              }}  className={style.invoiceContainer}>
                        <div
                          className={style.invoiceIcon}
                        >
                         <GrDocumentPdf/>
                        </div>
                        <div className={style.invoiceName}>
                          <p>PDF</p>
                        </div>
                      </div>
            </div>
            </div>
          </div>
      </>
      :
      ''}
    </div>
  );
};

export default BulkUploadSection;
