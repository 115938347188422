import React, { useEffect, useRef, useState } from "react";
import style from "./Upload.module.css";
import { useNavigate,useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { UserProfile } from "../../action/User";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Pagination/Pagination";
import { MdClose } from "react-icons/md";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Loader from "../Loader/Loader";
import { MdOutlineSearch } from "react-icons/md";
import { PiListBold } from "react-icons/pi";
import { BsGrid } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";

const Upload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location=useLocation()
  const voucher_Type=location.state?.type

  const { profile } = useSelector((state) => state.profileData);
  // console.log(profile);

  const [isLoading, setIsLoading] = useState(true);
  const [invoiceList, setInvoiceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [invoiceExpansion, setInvoiceExpansion] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState("");
  const [discount, setDiscount] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [grantTotal, setGrantTotal] = useState("");
  const [netTotal, setNetTotal] = useState("");
  const [tax, setTax] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [paidTo, setPaidTo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [paidBy, setPaidBy] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [idValue, setIdValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [itemList, setItemList] = useState([]);
  const [newRow, setNewRow] = useState(false);
  const [addButtonShow, setAddButtonShow] = useState(false);
  const [newItemValues, setNewItemValues] = useState({
    itemName: "",
    rate: "",
    quantity: "",
    netAmount: "",
  });
  const [newPaymentValues, setNewPaymentValues] = useState({
    paidBy: "",
    amount: "",
    transactionMode: "",
  });
  const [newBankValues, setNewBankValues] = useState({
    date: "",
    transcationAccount: "",
    description: "",
    amountDebit: "",
    amountCredit: "",
  });
  const [newPurchaseValues, setNewPurchaseValues] = useState({
    itemName: "",
    rate: "",
    quantity: "",
    netAmount: "",
    tax: "",
  });
  const [newExpanseValues, setNewExpanseValues] = useState({
    expanceLedger: "",
    amount: "",
    transactionMode: "",
  });
  const [listView, setListView] = useState(true);
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const[normalView,setNormalView]=useState(true)
  const [searchArray, setSearchArray] = useState([]);
  const [searchView, setSearchView] = useState(false);
  const[filterData,setFilterData]=useState([])
  const[filterView,setFilterView]=useState(false)
  const[selectedStatus,setSelectedStatus]=useState("")
  const[deleteInvoice,setDeleteInvoice]=useState(false)
  const[selectedVoucherName,setSelectedVoucherName]=useState("")
  const[validateConfirm,setValidateConfirm]=useState(false)
  const[showItemName,setShowItemsName]=useState(false)
  const[showAccountName,setShowAccountName]=useState(false)
  const[itemNameList,setItemnamelist]=useState([])
  const[accountNameList,setaccountNamelist]=useState([])
  const [selectedItem, setSelectedItem] = useState('');
  const [firstClickedRowIndex, setFirstClickedRowIndex] = useState(null);
  const[startDate,setStartDate]=useState("")
  const[endDate,setEndDate]=useState("")
  const [firstClickedNewRowIndex, setFirstClickedNewRowIndex] = useState(null);
  const[errorPopup,setErrorPopup]=useState(false)
  const[errorFilename,setErrorFileName]=useState("")
  const[retryPopup,setRetryPopup]=useState(false)
  const[retryStatus,setRetryStatus]=useState('')

  const scrollContainerRef = useRef();
  const filterContainerRef=useRef();
  const searchContainerRef=useRef();
  const progressRef = useRef();
  const roomId = localStorage.getItem("RoomId");
  const organizationId = localStorage.getItem("OrgId");

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
 
  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    if (selectedType === "payment") {
      setNewPaymentValues({ ...newPaymentValues, [name]: value });
    } else if (selectedType === "bank") {
      setNewBankValues({ ...newBankValues, [name]: value });
    } else if (selectedType === "purchase") {
      setNewPurchaseValues({ ...newPurchaseValues, [name]: value });
    } else if (selectedType === "expense") {
      setNewExpanseValues({ ...newExpanseValues, [name]: value });
    } else if (selectedType === "sale") {
      setNewItemValues({ ...newItemValues, [name]: value });
    }

    setAddButtonShow(true);
  };

  const addItemToTable = () => {
    if (selectedType === "payment") {
      if (
        newPaymentValues.paidBy ||
        newPaymentValues.amount ||
        newPaymentValues.transactionMode
      ) {
        const newItem = {
          Paidby: newPaymentValues.paidBy,
          Amount: newPaymentValues.amount,
          TransactionMode: newPaymentValues.transactionMode,
        };
        setItemList([...itemList, newItem]);
      }
      setNewPaymentValues({
        paidBy: "",
        amount: "",
        transactionMode: "",
      });
    } else if (selectedType === "bank") {
      if (
        newBankValues.date ||
        newBankValues.transcationAccount ||
        newBankValues.description ||
        newBankValues.amountDebit ||
        newBankValues.amountCredit
      ) {
        const newItem = {
          Date: newBankValues.date,
          TransactionAccount: newBankValues.transcationAccount,
          Description: newBankValues.description,
          AmountDebit: newBankValues.amountDebit,
          AmountCredit: newBankValues.amountCredit,
        };
        setItemList([...itemList, newItem]);
      }
      setNewBankValues({
        date: "",
        transcationAccount: "",
        description: "",
        amountDebit: "",
        amountCredit: "",
      });
    } else if (selectedType === "purchase") {
      if (
        newPurchaseValues.itemName ||
        newPurchaseValues.rate ||
        newPurchaseValues.quantity ||
        newPurchaseValues.netAmount ||
        newPurchaseValues.tax
      ) {
        const newItem = {
          "Item Name": newPurchaseValues.itemName,
          Rate: newPurchaseValues.rate,
          Quantity: newPurchaseValues.quantity,
          "Net Amount": newPurchaseValues.netAmount,
          Tax: newPurchaseValues.tax,
        };
        setItemList([...itemList, newItem]);
      }
      setNewPurchaseValues({
        itemName: "",
        rate: "",
        quantity: "",
        netAmount: "",
        tax: "",
      });
    } else if (selectedType === "expense") {
      if (
        newExpanseValues.expanceLedger ||
        newExpanseValues.amount ||
        newExpanseValues.transactionMode
      ) {
        const newItem = {
          ExpenseLedger: newExpanseValues.expanceLedger,
          Amount: newExpanseValues.amount,
          TransactionMode: newExpanseValues.transactionMode,
        };
        setItemList([...itemList, newItem]);
      }
      setNewExpanseValues({
        expanceLedger: "",
        amount: "",
        transactionMode: "",
      });
    } else if (selectedType === "sale") {
      if (
        newItemValues.itemName ||
        newItemValues.rate ||
        newItemValues.quantity ||
        newItemValues.netAmount
      ) {
        const newItem = {
          "Item Name": newItemValues.itemName,
          Rate: newItemValues.rate,
          Quantity: newItemValues.quantity,
          "Net Amount": newItemValues.netAmount,
        };

        setItemList([...itemList, newItem]);
      }
      setNewItemValues({
        itemName: "",
        rate: "",
        quantity: "",
        netAmount: "",
      });
    }

    console.log(itemList);
  };

  const handleEditChange = (e, index, field) => {
    const updatedList = [...itemList];
    updatedList[index][field] = e.target.value;
    setItemList(updatedList);
  };

  const validData = () => {
    let newData;

    if (selectedType === "payment") {
      newData = {
        Date: date,
        VoucherNumber: voucherNumber,
        Paidto: paidTo,
        TotalAmount: totalAmount,
        Details: itemList,
      };
    } else if (selectedType === "bank") {
      newData = {
        ["Bank Name"]: bankName,
        ["Account Number"]: accountNumber,
        ["From Date"]: dateFrom,
        ["To Date"]: dateTo,
        Details: itemList,
        Id:idValue
      };
    } else if (selectedType === "purchase") {
      newData = {
        ["Invoice Number"]: invoiceNumber,
        Date: date,
        CustomerName: customerName,
        ["Grand Total"]: grantTotal,
        Discount: discount,
        ["Net Total"]: netTotal,
        Items: itemList,
        Id:idValue
      };
    } else if (selectedType === "expense") {
      newData = {
        Date: date,
        VoucherNumber: voucherNumber,
        Paidby: paidBy,
        TotalAmount: totalAmount,
        Details: itemList,
      };
    } else if (selectedType === "sale") {
      newData = {
        ["Invoice Number"]: invoiceNumber,
        Date: date,
        Discount: discount,
        CustomerName: customerName,
        ["Grand Total"]: grantTotal,
        Tax: tax,
        ["Net Total"]: netTotal,
        Items: itemList,
        Id:idValue
      };
    }

    validateList(newData);
  };

  async function UploadList() {
    await fetch(`${baseUrl}/room/messages`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const reversedData = data.data.slice().reverse();
          setInvoiceList(reversedData);
          setIsLoading(false);
        }
      });
  }

  async function singleDetail(id) {
    await fetch(`${baseUrl}/room/messages/individual`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        document_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data[0].content === "") {
          setErrorMessage(true);
        } else {
          setInvoiceExpansion(true);
          const contentObject = data.data[0].content;
          console.log(contentObject);
          const newObject = contentObject.replace(/'/g, '"');
          console.log(newObject);
          const jsonObject = JSON.parse(newObject);
          console.log(jsonObject);
          if (data.data[0].type === "sale") {
            setInvoiceNumber(jsonObject["Invoice Number"]);
            setDate(jsonObject.Date);
            setDiscount(jsonObject.Discount);
            setCustomerName(jsonObject.CustomerName);
            setGrantTotal(jsonObject["Grand Total"]);
            setTax(jsonObject.Tax);
            setNetTotal(jsonObject["Net Total"]);
            setItemList(jsonObject.Items);
            setIdValue(jsonObject.Id);
          } else if (data.data[0].type === "purchase") {
            setInvoiceNumber(jsonObject["Invoice Number"]);
            setDate(jsonObject.Date);
            setDiscount(jsonObject.Discount);
            setCustomerName(jsonObject.CustomerName);
            setGrantTotal(jsonObject["Grand Total"]);
            setTax(jsonObject.Tax);
            setNetTotal(jsonObject["Net Total"]);
            setItemList(jsonObject.Items);
            setIdValue(jsonObject.Id);
          } else if (data.data[0].type === "bank") {
            setBankName(jsonObject["Bank Name"]);
            console.log(jsonObject["Bank Name"]);
            setAccountNumber(jsonObject["Account Number"]);
            setItemList(jsonObject.Details);
            setDateFrom(jsonObject["From Date"]);
            setDateTo(jsonObject["To Date"]);
            setIdValue(jsonObject.Id);
          } else if (data.data[0].type === "payment") {
            setDate(jsonObject.Date);
            setVoucherNumber(jsonObject.VoucherNumber);
            setPaidTo(jsonObject.Paidto);
            setTotalAmount(jsonObject.TotalAmount);
            setItemList(jsonObject.Details);
          } else if (data.data[0].type === "expense") {
            setDate(jsonObject.Date);
            setVoucherNumber(jsonObject.VoucherNumber);
            setPaidBy(jsonObject.Paidby);
            setTotalAmount(jsonObject.TotalAmount);
            setItemList(jsonObject.Details);
          }
        }
      });
  }

  async function validateList(bodyData) {
    await fetch(`${baseUrl}/update/invoice`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        document_id: documentId,
        invoice: bodyData,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setInvoiceExpansion(false);
          setNewRow(false);
          setValidateConfirm(false)
        }
      });
  }
  async function retryUpload() {
    await fetch(`${baseUrl}/error/retry`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        doc_id: documentId,
        org_id:organizationId,
        type:"web",
        title:selectedType,
        url:selectedImage
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setErrorPopup(false)
          setRetryStatus('Success')
          setTimeout(() => {
            setRetryPopup(false)
            setRetryStatus('')
            UploadList()
          }, 2000);
        }
      });
  }

  async function voucherDelete() {
    await fetch(`${baseUrl}/voucher/delete`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        doc_id: documentId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setDocumentId("")
          setDeleteInvoice(false)
          setNormalView(true)
          setErrorPopup(errorPopup?setErrorPopup(false):'')
          UploadList()
        }
      });
  }

  async function filterMessages(filter,status) {

    await fetch(`${baseUrl}/filter`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        type: filter,
        status:status,
        from_date_str:startDate,
        to_date_str:endDate
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          const reversedData = data.data.slice().reverse();
          setFilterData(reversedData)
          setShowFilter(false)
          setFilterView(true)
          setNormalView(false)
          setSearchView(false)
        }
      });
  }

  async function searchMessage() {
    await fetch(`${baseUrl}/searching`, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        search: search,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const reversedData = data.data.slice().reverse();
          setSearchArray(reversedData);
          setSearchView(true);
        }
      });
  }

  async function getAllItemNames() {
    await fetch("https://cpanel.q-hawk.com/product/GetAllProductForAI", {
      method: "POST",
      body: JSON.stringify({
        org_id:750
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        
         setItemnamelist(data)
        
      });
  }
  async function getAllAccountNames() {
    await fetch("https://cpanel.q-hawk.com/customer/GetAllCOAForAI", {
      method: "POST",
      body: JSON.stringify({
        org_id:750
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
         setaccountNamelist(data)
        
      });
  }
  
  
  useEffect(() => {
    if (search !== "") {
      searchMessage();
      setNormalView(false)
    } else {
      setSearchView(false);
      setSearchArray([]);
      // setNormalView(true)
    }
  }, [search]);

  const [normalViewPage, setNormalViewPage] = useState(0);
  const [searchViewPage, setSearchViewPage] = useState(0);
  const [filterViewPage, setFilterViewPage] = useState(0);

  const getVisibleData = (view) => {
    const currentPage =
      view === 'normal' ? normalViewPage : view === 'search' ? searchViewPage : filterViewPage;
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    switch (view) {
      case 'normal':
        return invoiceList.slice(startIndex, endIndex);
      case 'search':
        return searchArray.slice(startIndex, endIndex);
      case 'filter':
        return filterData.slice(startIndex, endIndex);
      default:
        return [];
    }
  };
  const handlePageChange = (view, { selected }) => {
    switch (view) {
      case 'normal':
        setNormalViewPage(selected);
        break;
      case 'search':
        setSearchViewPage(selected);
        break;
      case 'filter':
        setFilterViewPage(selected);
        break;
      default:
        break;
    }
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
    if (searchContainerRef.current) {
      searchContainerRef.current.scrollTop = 0;
    }
    if (filterContainerRef.current) {
      filterContainerRef.current.scrollTop = 0;
    }
  };

  const [itemsPerPage, setItemsPerPage] = useState(25);
  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setItemsPerPage(selectedItemsPerPage);

  };

  useEffect(() => {
    // dispatch(UserProfile());
  }, []);

  useEffect(() => {
    // UploadList();
  }, []);

  useEffect(() => {
    if (voucher_Type !== undefined) {
      const newtype=""
      const newstatus=""
      if (voucher_Type === "expense") {
        setSelectedFilter("expense");
        filterMessages("expense",newstatus)
      } else if (voucher_Type === "payment") {
        setSelectedFilter("payment");
        filterMessages("payment",newstatus)
      }
      else if (voucher_Type === "sale_invoice") {
        setSelectedFilter("sale_invoice");
        filterMessages("sale_invoice",newstatus)
      }
      else if (voucher_Type === "bank_statement") {
        setSelectedFilter("bank_statement");
        filterMessages("bank_statement",newstatus)
      }
      else if (voucher_Type === "purchase_invoice") {
        setSelectedFilter("purchase_invoice");
        filterMessages("purchase_invoice",newstatus)
      }else if(voucher_Type==="Error"){
        setSelectedStatus("Error")
        filterMessages(newtype,"Error")
      }

    }
    else{
      UploadList()
    }
  }, [voucher_Type]);


  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });
  const [itemNameModified, setItemNameModified] = useState(Array(itemList.length).fill(false));

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={style.Container}>
      <ToastContainer/>
      <div className={style.Header}>
        <div className={style.SearchContainer}>
          <div className={style.Search}>
            <MdOutlineSearch className={style.SearchIcon} />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="input"
              placeholder="Search Here..."
            />
            {search !== "" ? (
              <MdClose
                onClick={() => {
                  setSearch("");
                  setSearchArray([]);
                  setSearchView(false);
                }}
                className={style.CloseIcon}
              />
            ) : (
              ""
            )}
          </div>
          <div className={style.FilterIcon}>
            <IoFilter className={filterView ? style.blueIcon : ''} onClick={handleFilterClick} />
            {filterView && (
        <div className={style.TooltipText}>Filters</div>
      )}
          </div>
          {/* {filterView?
          <div className={style.FilterIconbutton}>
            <button onClick={()=>{
              setFilterView(false)
              setFilterData([])
              setNormalView(true)
              setSearchView(false)
              setSelectedFilter("")
              setSelectedStatus("")
            }}>Clear Filter</button>
          </div>
          :''} */}
        </div>
        <div className={style.uploadButton}>
          {normalView?
        <div className={style.viewIconContainer}>
            <div
              className={`${style.listView} ${
                listView ? style.selected : ""
              }`}
              onClick={() => {
                setListView(true);
              }}
            >
              <PiListBold />
            </div>
            <div
              className={`${style.gridView} ${
                !listView ? style.selected : ""
              }`}
              onClick={() => {
                setListView(false);
              }}
            >
              <BsGrid />
            </div>
          </div>
          :''}
          <button
            className={style.buttonStyle}
            onClick={() => {
              navigate("/voucher/upload");
            }}
          >
            Upload
          </button>
        </div>
      </div>
      <div className={style.uploadList}>
       {normalView&&(
        <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div ref={scrollContainerRef} className={style.uploadBody}>
              {!listView ? (
                <div className={style.bodyGrid}>
                  {invoiceList &&
                    invoiceList.length > 0 &&
                    getVisibleData('normal').map((list, i) => {
                      const localTime = new Date(
                        list.server_time
                      ).toLocaleString();
                      return (
                        <div className={style.cardContent} key={i}>
                          <div className={style.cardHead}>
                            <div className={style.contentLeft}>
                              <div className={style.textNames}>
                                <p>Type :</p>
                                <p>Status :</p>
                              </div>
                              <div className={style.textValues}>
                                <p  style={{
                                    color:
                                      list.type === "bank"
                                        ? "#4cb050"
                                        : list.type === "sale"
                                        ? "#9c5ca6"
                                        : list.type === "purchase"
                                        ? "#e85d72"
                                        : list.type === "payment"
                                        ? "#7aa6cb"
                                        : list.type === "expense"
                                        ? "#ea913f"
                                        : "",
                                  }}>
                                  {list.type === "bank" && "Bank"}
                                  {list.type === "sale" && "Sale "}
                                  {list.type === "purchase" && "Purchase"}
                                  {list.type === "payment" && "Payment"}
                                  {list.type === "expense" && "Expenses"}
                                </p>
                                <p
                                  style={{
                                    color:
                                      list.status === "Finished"
                                        ? "#0bf207"
                                        : list.status === "Draft"
                                        ? "#cf8c11"
                                        : list.status === "In Process"
                                        ? "#d1cb1d"
                                        : "red",
                                  }}
                                >
                                  {list.status === "Finished" && "Finished"}
                                  {list.status === "Draft" && "Draft"}
                                  {list.status === "In Process" &&
                                    "In Process"}
                                    {list.status === "Error" &&
                                    "Error"}
                                </p>
                              </div>
                            </div>
                            <div className={style.contentRight}>
                              <img src={list.url} alt="" />
                            </div>
                          </div>
                          <div className={style.cardBottom}>
                            <div className={style.CardText}>
                              {list.content === "failed" ? (
                                <p onClick={() => {
                                  setSelectedImage(list.url);
                                  setSelectedType(list.type);
                                  setErrorPopup(true)
                                  setDocumentId(list.document_id);
                                  setErrorFileName(list.show_file_name?list.show_file_name:list.file_name)
                                  setSelectedVoucherName(list.show_file_name?list.show_file_name:list.file_name)
                                }} style={{ color: "red", cursor: "pointer" }}>
                                 {list.show_file_name?truncateText(list.show_file_name,23 ):truncateText(list.file_name,25 )} Something is wrong!
                                </p>
                              ) : (
                                <p
                                style={{ color: "#b30059" }}
                                  onClick={() => {
                                    setSelectedImage(list.url);
                                    setSelectedType(list.type);
                                    singleDetail(list.document_id);
                                    setDocumentId(list.document_id);
                                  }}
                                >
                                  {list.show_file_name?truncateText(list.show_file_name,23 ):truncateText(list.file_name,25 )}
                                </p>
                              )}
                            </div>
                            <div className={style.DeleteContainer}><p>{localTime}</p> <div className={style.DeleteICon}><RiDeleteBinLine onClick={()=>{
                              setDeleteInvoice(true)
                              setDocumentId(list.document_id);
                              setSelectedVoucherName(list.file_name)
                            }} /></div></div>
                            
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className={style.bodyList}>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Last Modified</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceList && invoiceList.length > 0
                        ? getVisibleData('normal').map((invoice, i) => {
                            const localTime = new Date(
                              invoice.server_time
                            ).toLocaleString();
                            return (
                              <tr key={i}>
                                <td>
                                  {invoice.content === "failed" ? (
                                    <p
                                    onClick={() => {
                                      setSelectedImage(invoice.url);
                                      setSelectedType(invoice.type);
                                      setErrorPopup(true)
                                      setDocumentId(invoice.document_id);
                                      setErrorFileName(invoice.show_file_name?invoice.show_file_name:invoice.file_name)
                                      setSelectedVoucherName(invoice.show_file_name?invoice.show_file_name:invoice.file_name)
                                    }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    >
                                     {invoice.show_file_name?invoice.show_file_name:invoice.file_name} Something is wrong!
                                    </p>
                                  ) : (
                                    <p
                                      onClick={() => {
                                        setSelectedImage(invoice.url);
                                        setSelectedType(invoice.type);
                                        singleDetail(invoice.document_id);
                                        setDocumentId(invoice.document_id);
                                      }}
                                    >
                                      {invoice.show_file_name?invoice.show_file_name:invoice.file_name}
                                    </p>
                                  )}
                                </td>
                                <td
                                  style={{
                                    color:
                                      invoice.type === "bank"
                                        ? "#4cb050"
                                        : invoice.type === "sale"
                                        ? "#9c5ca6"
                                        : invoice.type === "purchase"
                                        ? "#e85d72"
                                        : invoice.type === "payment"
                                        ? "#7aa6cb"
                                        : invoice.type === "expense"
                                        ? "#ea913f"
                                        : "",
                                  }}
                                >
                                  {" "}
                                  {invoice.type === "bank" && "Bank"}
                                  {invoice.type === "sale" && "Sale "}
                                  {invoice.type === "purchase" &&
                                    "Purchase"}
                                  {invoice.type === "payment" && "Payment"}
                                  {invoice.type === "expense" && "Expenses"}
                                </td>
                                <td
                                  style={{
                                    color:
                                      invoice.status === "Finished"
                                        ? "#0bf207"
                                        : invoice.status === "Draft"
                                        ? "#cf8c11"
                                        : invoice.status === "In Process"
                                        ? "#d1cb1d"
                                        : "red",
                                  }}
                                >
                                  {invoice.status === "Finished" && "Finished"}
                                  {invoice.status === "Draft" && "Draft"}
                                  {invoice.status === "In Process"&&
                                    "In Process"}
                                    {invoice.status === "Error"&&
                                    "Error"}
                                </td>
                                <td>{localTime}</td>
                                <td> <div className={style.DeleteICon}><RiDeleteBinLine onClick={()=>{
                              setDeleteInvoice(true)
                              setDocumentId(invoice.document_id);
                              setSelectedVoucherName(invoice.file_name)
                            }}/></div></td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              )}
              
            <Pagination
              pageCount={Math.ceil(invoiceList.length / itemsPerPage)}
              onPageChange={(selected) => handlePageChange('normal', selected)}
              onItemsPerPageChange={handleItemsPerPageChange}
              currentPage={normalViewPage}
            />
            </div>
            
          </>
        )}
       </>
       )}
       {searchView&&(
         <div ref={searchContainerRef} className={style.SearchList}>
         <table >
           <thead>
             <tr>
               <th>Name</th>
               <th>Type</th>
               <th>Status</th>
               <th>Last Modified</th>
               <th>Actions</th>
             </tr>
           </thead>
           <tbody>
                       {searchArray && searchArray.length > 0
                         ? getVisibleData('search').map((invoice, i) => {
                             const localTime = new Date(
                               invoice.server_time
                             ).toLocaleString();
                             return (
                               <tr key={i}>
                                 <td>
                                   {invoice.content === "failed" ? (
                                     <p
                                     onClick={() => {
                                      setSelectedImage(invoice.url);
                                      setSelectedType(invoice.type);
                                      setErrorPopup(true)
                                      setDocumentId(invoice.document_id);
                                      setErrorFileName(invoice.show_file_name?invoice.show_file_name:invoice.file_name)
                                      setSelectedVoucherName(invoice.show_file_name?invoice.show_file_name:invoice.file_name)
                                    }}
                                       style={{
                                         color: "red",
                                         cursor: "auto",
                                       }}
                                     >
                                      {invoice.show_file_name?invoice.show_file_name:invoice.file_name} Something is wrong!
                                     </p>
                                   ) : (
                                     <p
                                       onClick={() => {
                                         setSelectedImage(invoice.url);
                                         setSelectedType(invoice.type);
                                         singleDetail(invoice.document_id);
                                         setDocumentId(invoice.document_id);
                                       }}
                                     >
                                       {invoice.show_file_name?invoice.show_file_name:invoice.file_name}
                                     </p>
                                   )}
                                 </td>
                                 <td
                                   style={{
                                     color:
                                       invoice.type === "bank"
                                         ? "#4cb050"
                                         : invoice.type === "sale"
                                         ? "#9c5ca6"
                                         : invoice.type === "purchase"
                                         ? "#e85d72"
                                         : invoice.type === "payment"
                                         ? "#7aa6cb"
                                         : invoice.type === "expense"
                                         ? "#ea913f"
                                         : "",
                                   }}
                                 >
                                   {" "}
                                   {invoice.type === "bank" && "Bank"}
                                   {invoice.type === "sale" && "Sale "}
                                   {invoice.type === "purchase" &&
                                     "Purchase"}
                                   {invoice.type === "payment" && "Payment"}
                                   {invoice.type === "expense" && "Expenses"}
                                 </td>
                                 <td
                                  style={{
                                    color:
                                      invoice.status === "Finished"
                                        ? "#0bf207"
                                        : invoice.status === "Draft"
                                        ? "#cf8c11"
                                        : invoice.status === "In Process"
                                        ? "#d1cb1d"
                                        : "red",
                                  }}
                                >
                                  {invoice.status === "Finished" && "Finished"}
                                  {invoice.status === "Draft" && "Draft"}
                                  {invoice.status === "In Process"&&
                                    "In Process"}
                                    {invoice.status === "Error"&&
                                    "Error"}
                                </td>
                                 <td>{localTime}</td>
                                 <td> <div className={style.DeleteICon}><RiDeleteBinLine onClick={()=>{
                              setDeleteInvoice(true)
                              setDocumentId(invoice.document_id);
                              setSelectedVoucherName(invoice.file_name)
                            }} /></div></td>
                               </tr>
                             );
                           })
                         : ""}
                     </tbody>
         </table>
         <Pagination
              pageCount={Math.ceil(searchArray.length / itemsPerPage)}
              onPageChange={(selected) => handlePageChange('search', selected)}
              onItemsPerPageChange={handleItemsPerPageChange}
              currentPage={normalViewPage}
            />
       </div>
       )}
        {filterView&&(
         <div ref={filterContainerRef} className={style.SearchList}>
         <table >
           <thead>
             <tr>
               <th>Name</th>
               <th>Type</th>
               <th>Status</th>
               <th>Last Modified</th>
               <th>Actions</th>
             </tr>
           </thead>
           <tbody>
                       {filterData && filterData.length > 0
                         ? getVisibleData('filter').map((invoice, i) => {
                             const localTime = new Date(
                               invoice.server_time
                             ).toLocaleString();
                             return (
                               <tr key={i}>
                                 <td>
                                   {invoice.content === "failed" ? (
                                     <p
                                     onClick={() => {
                                      setSelectedImage(invoice.url);
                                      setSelectedType(invoice.type);
                                      setErrorPopup(true)
                                      setDocumentId(invoice.document_id);
                                      setErrorFileName(invoice.show_file_name?invoice.show_file_name:invoice.file_name)
                                      setSelectedVoucherName(invoice.show_file_name?invoice.show_file_name:invoice.file_name)
                                    }}
                                       style={{
                                         color: "red",
                                         cursor: "auto",
                                       }}
                                     >
                                      {invoice.show_file_name?invoice.show_file_name:invoice.file_name} Something is wrong!
                                     </p>
                                   ) : (
                                     <p
                                       onClick={() => {
                                         setSelectedImage(invoice.url);
                                         setSelectedType(invoice.type);
                                         singleDetail(invoice.document_id);
                                         setDocumentId(invoice.document_id);
                                       }}
                                     >
                                       {invoice.show_file_name?invoice.show_file_name:invoice.file_name}
                                     </p>
                                   )}
                                 </td>
                                 <td
                                   style={{
                                     color:
                                       invoice.type === "bank"
                                         ? "#4cb050"
                                         : invoice.type === "sale"
                                         ? "#9c5ca6"
                                         : invoice.type === "purchase"
                                         ? "#e85d72"
                                         : invoice.type === "payment"
                                         ? "#7aa6cb"
                                         : invoice.type === "expense"
                                         ? "#ea913f"
                                         : "",
                                   }}
                                 >
                                   {" "}
                                   {invoice.type === "bank" && "Bank"}
                                   {invoice.type === "sale" && "Sale "}
                                   {invoice.type === "purchase" &&
                                     "Purchase"}
                                   {invoice.type === "payment" && "Payment"}
                                   {invoice.type === "expense" && "Expenses"}
                                 </td>
                                 <td
                                  style={{
                                    color:
                                      invoice.status === "Finished"
                                        ? "#0bf207"
                                        : invoice.status === "Draft"
                                        ? "#cf8c11"
                                        : invoice.status === "In Process"
                                        ? "#d1cb1d"
                                        : "red",
                                  }}
                                >
                                  {invoice.status === "Finished" && "Finished"}
                                  {invoice.status === "Draft" && "Draft"}
                                  {invoice.status === "In Process"&&
                                    "In Process"}
                                    {invoice.status === "Error"&&
                                    "Error"}
                                </td>
                                 <td>{localTime}</td>
                                 <td> <div className={style.DeleteICon}><RiDeleteBinLine onClick={()=>{
                              setDeleteInvoice(true)
                              setDocumentId(invoice.document_id);
                              setSelectedVoucherName(invoice.file_name)
                            }}/></div></td>
                               </tr>
                             );
                           })
                         : ""}
                     </tbody>
         </table>
         <Pagination
              pageCount={Math.ceil(filterData.length / itemsPerPage)}
              onPageChange={(selected) => handlePageChange('filter', selected)}
              onItemsPerPageChange={handleItemsPerPageChange}
              currentPage={normalViewPage}
            />
       </div>
       )}
      </div>

      {invoiceExpansion && selectedType === "payment" ? (
        <>
          <div
            onClick={() => {
              setInvoiceExpansion(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.purchaseInvoice}>
            
            <div className={style.sectionHeader}>
             <div className={style.sectionHeaderText} > <h5> Payment</h5></div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setInvoiceExpansion(false);
                  setSelectedImage("");
                  setNewRow(false);
                }}
              />
            </div>
            </div>
            <div className={style.mainSection}>
              <div className={style.sectionLeft}>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Date</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={date}
                      readOnly
                      className={style.itemInput}
                    />
                    <input
                      type="date"
                      name=""
                      id=""
                      className={style.dateSelection}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Voucher Number</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={voucherNumber}
                      className={style.itemInput}
                      onChange={(e) => {
                        setVoucherNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Paid To</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={paidTo}
                      className={style.itemInput}
                      onChange={(e) => {
                        setPaidTo(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.itemTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>Payment Ledger</th>
                        <th>Amount</th>
                        <th>Transaction Mode</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemList &&
                        itemList.length > 0 &&
                        itemList.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <input
                                onClick={()=>{
                                  setShowAccountName(true)
                                  setSelectedItem(list.Paidby);
                                  getAllAccountNames()
                                  setFirstClickedRowIndex(i);
                                }}
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.Paidby}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Paidby")
                                  }
                                  style={{ color: itemNameModified[i] ? "black" : "red" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.Amount}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Amount")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.TransactionMode}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "TransactionMode")
                                  }
                                />
                              </td>
                              <td></td>
                            </tr>
                          );
                        })}
                      {newRow ? (
                        <tr>
                          <td>
                            <input
                            onClick={()=>{
                              getAllAccountNames()
                              setShowAccountName(true)
                              setSelectedItem(newItemValues.paidBy)
                              setFirstClickedNewRowIndex("paidBy");
                            }}
                              type="text"
                              name="paidBy"
                              value={newPaymentValues.paidBy}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amount"
                              value={newPaymentValues.amount}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="transactionMode"
                              value={newPaymentValues.transactionMode}
                              onChange={handleNewItemChange}
                            />
                          </td>

                          <td>
                            {addButtonShow ? (
                              <button
                                onClick={() => {
                                  addItemToTable();
                                  setNewRow(false);
                                  setAddButtonShow(false);
                                }}
                              >
                                Add
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={style.addButton}>
                  <button
                    onClick={() => {
                      setNewRow(true);

                      addItemToTable();
                    }}
                  >
                    Add Item
                  </button>
                </div>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>TotalAmount</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={totalAmount}
                      className={style.itemInput}
                      onChange={(e) => {
                        setTotalAmount(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.validateButton}>
                  <button
                    onClick={() => {
                      setValidateConfirm(true)
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className={style.sectionRight}>
                <div className={style.imageSelected}>
                  <img src={selectedImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {invoiceExpansion && selectedType === "bank" ? (
        <>
          <div
            onClick={() => {
              setInvoiceExpansion(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.purchaseInvoice}>
            
            <div className={style.sectionHeader}>
              <div className={style.sectionHeaderText}><h5> Bank Statement</h5></div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setInvoiceExpansion(false);
                  setSelectedImage("");
                  setNewRow(false);
                }}
              />
            </div>
            </div>
            <div className={style.mainSection}>
              <div className={style.sectionLeft}>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Bank Name</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={bankName}
                      className={style.itemInput}
                      onChange={(e) => {
                        setBankName(e.target.value);
                      }}
                      style={{ color: idValue ? "black" : "red" }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Account Number</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={accountNumber}
                      className={style.itemInput}
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>From</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={dateFrom}
                      className={style.itemInput}
                    />
                    <input
                      type="date"
                      name=""
                      id=""
                      className={style.dateSelection}
                      onChange={(e) => {
                        setDateFrom(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>To</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={dateTo}
                      className={style.itemInput}
                    />
                    <input
                      type="date"
                      name=""
                      id=""
                      className={style.dateSelection}
                      onChange={(e) => {
                        setDateTo(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.itemTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction..</th>
                        <th>Description</th>
                        <th>Debit..</th>
                        <th>Credit..</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemList &&
                        itemList.length > 0 &&
                        itemList.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.Date}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Date")
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.TransactionAccount}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "TransactionAccount")
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.Description}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Description")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.AmountDebit}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "AmountDebit")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.AmountCredit}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "AmountCredit")
                                  }
                                />
                              </td>
                              <td></td>
                            </tr>
                          );
                        })}

                      {newRow ? (
                        <tr>
                          <td>
                            <input
                              type="text"
                              name="date"
                              value={newBankValues.date}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="transcationAccount"
                              value={newBankValues.transcationAccount}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="description"
                              value={newBankValues.description}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amountDebit"
                              value={newBankValues.amountDebit}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amountCredit"
                              value={newBankValues.amountCredit}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            {addButtonShow ? (
                              <button
                                onClick={() => {
                                  addItemToTable();
                                  setNewRow(false);
                                  setAddButtonShow(false);
                                }}
                              >
                                Add
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={style.addButton}>
                  <button
                    onClick={() => {
                      setNewRow(true);
                      addItemToTable();
                    }}
                  >
                    Add Item
                  </button>
                </div>

                <div className={style.validateButton}>
                  <button
                    onClick={() => {
                     setValidateConfirm(true)
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className={style.sectionRight}>
                <div className={style.imageSelected}>
                  <img src={selectedImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {invoiceExpansion && selectedType === "purchase" ? (
        <>
          <div
            onClick={() => {
              setInvoiceExpansion(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.purchaseInvoice}>
            
            <div className={style.sectionHeader}>
             <div className={style.sectionHeaderText}> <h5>Purchase Invoice</h5></div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setInvoiceExpansion(false);
                  setSelectedImage("");
                  setNewRow(false);
                }}
              />
            </div>
            </div>
            <div className={style.mainSection}>
              <div className={style.sectionLeft}>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Invoice Number</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={invoiceNumber}
                      onChange={(e) => {
                        setInvoiceNumber(e.target.value);
                      }}
                      className={style.itemInput}
                    />
                  </div>

                  <div className={style.itemValue}>
                    <p>Date</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      className={style.itemInput}
                    />
                    <input
                      type="date"
                      name=""
                      id=""
                      className={style.dateSelection}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Supplier Name</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={customerName}
                      onChange={(e) => {
                        setCustomerName(e.target.value);
                      }}
                      className={style.itemInput}
                      style={{ color: idValue ? "black" : "red" }}
                    />
                  </div>
                </div>
                <div className={style.itemTable}>
                  <table>
                    <thead>
                      <th>Item Name</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Net..</th>
                      <th>Tax</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      {itemList &&
                        itemList.length > 0 &&
                        itemList.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td style={{ color: list.Id ? "black" : "red" }}>
                                <input
                                onClick={()=>{
                                  setShowItemsName(true)
                                  setSelectedItem(list["Item Name"]);
                                  getAllItemNames()
                                  setFirstClickedRowIndex(i);
                                }}
                                  type="text"
                                  name=""
                                  id=""
                                  value={list["Item Name"]}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Item Name")
                                  }
                                  style={{ color: itemNameModified[i] ? "black" : "red" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={list.Rate}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Rate")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={list.Quantity}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Quantity")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={list["Net Amount"]}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Net Amount")
                                  }
                                />
                              </td>

                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.Tax}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Tax")
                                  }
                                />
                              </td>
                              <td></td>
                            </tr>
                          );
                        })}

                      {newRow ? (
                        <tr>
                          <td>
                            <input
                            onClick={()=>{
                              getAllItemNames()
                              setShowItemsName(true)
                              setSelectedItem(newItemValues.itemName)
                              setFirstClickedNewRowIndex("itemName");
                            }}
                              type="text"
                              name="itemName"
                              value={newPurchaseValues.itemName}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="rate"
                              value={newPurchaseValues.rate}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="quantity"
                              value={newPurchaseValues.quantity}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="netAmount"
                              value={newPurchaseValues.netAmount}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="tax"
                              value={newPurchaseValues.tax}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            {addButtonShow ? (
                              <button
                                onClick={() => {
                                  addItemToTable();
                                  setNewRow(false);
                                  setAddButtonShow(false);
                                }}
                              >
                                Add
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>

                <div className={style.addButton}>
                  <button
                    onClick={() => {
                      setNewRow(true);

                      addItemToTable();
                    }}
                  >
                    Add Item
                  </button>
                </div>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Net Total</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={netTotal}
                      onChange={(e) => {
                        setNetTotal(e.target.value);
                      }}
                      className={style.itemInput}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Discount</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      className={style.itemInput}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Grant Total</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={grantTotal}
                      onChange={(e) => {
                        setGrantTotal(e.target.value);
                      }}
                      className={style.itemInput}
                    />
                  </div>
                </div>
                <div className={style.validateButton}>
                  <button
                    onClick={() => {
                      setValidateConfirm(true)
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className={style.sectionRight}>
                <div className={style.imageSelected}>
                  <img src={selectedImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {invoiceExpansion && selectedType === "expense" ? (
        <>
          <div
            onClick={() => {
              setInvoiceExpansion(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.purchaseInvoice}>
            
            <div className={style.sectionHeader}>
              <div className={style.sectionHeaderText}><h5>Expense</h5></div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setInvoiceExpansion(false);
                  setSelectedImage("");
                  setNewRow(false);
                }}
              />
            </div>
            </div>
            <div className={style.mainSection}>
              <div className={style.sectionLeft}>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Date</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={date}
                      className={style.itemInput}
                    />
                    <input
                      type="date"
                      name=""
                      id=""
                      className={style.dateSelection}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Voucher Number</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={voucherNumber}
                      className={style.itemInput}
                      onChange={(e) => {
                        setVoucherNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Paid By</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={paidBy}
                      className={style.itemInput}
                      onChange={(e) => {
                        setPaidBy(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.itemTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>Expense Ledger</th>
                        <th>Amount</th>
                        <th>Transaction Mode</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemList &&
                        itemList.length > 0 &&
                        itemList.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td style={{ color: list.Id ? "black" : "red" }}>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.ExpenseLedger}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "ExpenseLedger")
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.Amount}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "Amount")
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value={list.TransactionMode}
                                  onChange={(e) =>
                                    handleEditChange(e, i, "TransactionMode")
                                  }
                                />
                              </td>
                              <td></td>
                            </tr>
                          );
                        })}
                      {newRow ? (
                        <tr>
                          <td>
                            <input
                              type="text"
                              name="expanceLedger"
                              value={newExpanseValues.expanceLedger}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amount"
                              value={newExpanseValues.amount}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="transactionMode"
                              value={newExpanseValues.transactionMode}
                              onChange={handleNewItemChange}
                            />
                          </td>

                          <td>
                            {addButtonShow ? (
                              <button
                                onClick={() => {
                                  addItemToTable();
                                  setNewRow(false);
                                  setAddButtonShow(false);
                                }}
                              >
                                Add
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={style.addButton}>
                  <button
                    onClick={() => {
                      setNewRow(true);

                      addItemToTable();
                    }}
                  >
                    Add Item
                  </button>
                </div>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Total Amount </p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={totalAmount}
                      className={style.itemInput}
                      onChange={(e) => {
                        setTotalAmount(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.validateButton}>
                  <button
                    onClick={() => {
                      setValidateConfirm(true)
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className={style.sectionRight}>
                <div className={style.imageSelected}>
                  <img src={selectedImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {invoiceExpansion && selectedType === "sale" ? (
        <>
          <div
            onClick={() => {
              setInvoiceExpansion(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.purchaseInvoice}>
            
            <div className={style.sectionHeader}>
              <div className={style.sectionHeaderText}><h5> Sale Invoice</h5></div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setInvoiceExpansion(false);
                  setSelectedImage("");
                  setItemList([]);
                  setNewRow(false);
                }}
              />
            </div>
            </div>
            <div className={style.mainSection}>
              <div className={style.sectionLeft}>
                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Invoice Number</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      className={style.itemInput}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Date</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className={style.itemInput}
                    />
                    <input
                      type="date"
                      name=""
                      id=""
                      className={style.dateSelection}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Customer Name</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      className={style.itemInput}
                      style={{ color: idValue ? "black" : "red" }}
                    />
                  </div>
                </div>

                <div className={style.itemTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Rate</th>
                        <th>Quantity</th>
                        <th>Net Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemList.map((list, i) => (
                        <tr key={i}>
                          <td style={{ color: list.Id ? "black" : "red" }}>
                            <input
                            onClick={()=>{
                              setShowItemsName(true)
                              setSelectedItem(list["Item Name"]);
                              getAllItemNames()
                              setFirstClickedRowIndex(i);
                            }}
                              type="text"
                              value={list["Item Name"]}
                              onChange={(e) =>
                                handleEditChange(e, i, "Item Name")
                              }
                              style={{ color: itemNameModified[i] ? "black" : "red" }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={list.Rate}
                              onChange={(e) => handleEditChange(e, i, "Rate")}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={list.Quantity}
                              onChange={(e) =>
                                handleEditChange(e, i, "Quantity")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={list["Net Amount"]}
                              onChange={(e) =>
                                handleEditChange(e, i, "Net Amount")
                              }
                            />
                          </td>
                          <td></td>
                        </tr>
                      ))}

                      {newRow ? (
                        <tr>
                          <td>
                            <input
                            onClick={()=>{
                              getAllItemNames()
                              setShowItemsName(true)
                              setSelectedItem(newItemValues.itemName)
                              setFirstClickedNewRowIndex("itemName");
                            }}
                              type="text"
                              name="itemName"
                              value={newItemValues.itemName}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="rate"
                              value={newItemValues.rate}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="quantity"
                              value={newItemValues.quantity}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="netAmount"
                              value={newItemValues.netAmount}
                              onChange={handleNewItemChange}
                            />
                          </td>
                          <td>
                            {addButtonShow ? (
                              <button
                                onClick={() => {
                                  addItemToTable();
                                  setNewRow(false);
                                  setAddButtonShow(false);
                                }}
                              >
                                Add
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>

                <div className={style.addButton}>
                  <button
                    onClick={() => {
                      setNewRow(true);

                      addItemToTable();
                    }}
                  >
                    Add Item
                  </button>
                </div>

                <div className={style.invoiceSection}>
                  <div className={style.itemValue}>
                    <p>Tax </p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={tax}
                      onChange={(e) => setTax(e.target.value)}
                      className={style.itemInput}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Net Total </p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={netTotal}
                      onChange={(e) => setNetTotal(e.target.value)}
                      className={style.itemInput}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Discount </p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                      className={style.itemInput}
                    />
                  </div>
                  <div className={style.itemValue}>
                    <p>Grand Total</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={grantTotal}
                      onChange={(e) => setGrantTotal(e.target.value)}
                      className={style.itemInput}
                    />
                  </div>
                </div>

                <div className={style.validateButton}>
                  <button
                    onClick={() => {
                      setValidateConfirm(true)
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className={style.sectionRight}>
                <div className={style.imageSelected}>
                  <img src={selectedImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {errorMessage ? (
        <>
          <div
            onClick={() => {
              setErrorMessage(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.purchaseInvoice}>
           
            <div className={style.sectionHeader}>
             <div className={style.sectionHeaderText}> <h5></h5></div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setErrorMessage(false);
                }}
              />
            </div>
            </div>
            <div className={style.errorSection}>
              <Box sx={{ width: "70%" }}>
                <LinearProgress
                  variant="buffer"
                  value={progress}
                  valueBuffer={buffer}
                  sx={{ height: "5px" }}
                  color="secondary"
                />
              </Box>
              <h4>Processing ...! Please try again later</h4>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {showFilter?
      <>
      <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.Overlay}
          ></div>
        <div className={style.FilterPopup}>
          <h3>Filters</h3>
        <div className={style.FilterBody}>
          <div className={style.DateContainer}>
            <div className={style.startDate}>
              <label>Start Date</label>
              <input value={startDate} onChange={(e)=>{
                setStartDate(e.target.value)
              }} type="date"/>
            </div>
            <div className={style.startDate}>
              <label>End Date</label>
              <input value={endDate} onChange={(e)=>{
                setEndDate(e.target.value)
              }} type="date"/>
            </div>
          </div>
          <h4>Type</h4>
          <select
                className={style.Selection}
                name=""
                id=""
                onChange={(e) => {
                  const Type= e.target.value;
                  setSelectedFilter(Type)
                }}
                value={selectedFilter}
              >
                <option value="">Select Type</option>
                <option value="sale_invoice">Sale Invoice</option>
                <option value="purchase_invoice">Purchase Invoice</option>
                <option value="expense">Expense Voucher</option>
                <option value="bank_statement">Bank Statement</option>
                <option value="payment">Payment Voucher</option>
                
              </select>
          
          <h4>Status</h4>
          <select
                className={style.Selection}
                name=""
                id=""
                onChange={(e) => {
                  const status= e.target.value;
                  setSelectedStatus(status)
                }}
                value={selectedStatus}
              >
                <option value="">Select Status</option>
                <option value="In Process">Inprocess</option>
                <option value="Error">Error</option>
                <option value="Draft">Draft</option>
                <option value="Finished">Finished</option>
                
              </select>
        </div>
        <div className={style.Filterbuttons}>
          <button style={{backgroundColor:"#dd4949"}} onClick={()=>{
            if(selectedFilter!==""||selectedStatus!==""||startDate!==""){
              if(startDate!==""&&endDate===""){
                toast.error("Please select end date")
              }
              else{
                filterMessages(selectedFilter,selectedStatus)
              }
              
            }
          }}>Apply</button>
          <button style={{backgroundColor:"green"}} onClick={()=>{
            setShowFilter(false)
            
            if(!filterView){
              setStartDate('')
              setEndDate('')
              setSelectedFilter('')
              setSelectedStatus('')
            }
          }}>Cancel</button>
          {filterView?
          <button style={{backgroundColor:"green"}} onClick={()=>{
            setShowFilter(false)
            setFilterView(false)
            setSelectedStatus("")
            setSelectedFilter("")
            setNormalView(true)
            setEndDate("")
            setStartDate("")
            UploadList()
          }}>Clear</button>
          :''}
        </div>
  
        </div>
      </>
      :''}
      {deleteInvoice ? (
        <>
          <div
            onClick={() => {
              setDeleteInvoice(false);
              
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <MdClose
                onClick={() => {
                  setDeleteInvoice(false);
                  
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete <br/><span style={{color:"#b30059"}}>{selectedVoucherName}</span> ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                   voucherDelete()
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setDeleteInvoice(false);
                    
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {validateConfirm ? (
        <>
          <div
            onClick={() => {
              setValidateConfirm(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <MdClose
                onClick={() => {
                  setValidateConfirm(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Do you want to validate? </p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    addItemToTable();
                    validData();
                  }}
                >
                  Yes
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setValidateConfirm(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {showItemName?
      <>
      <div
            onClick={() => {
              setShowItemsName(false);
              setFirstClickedRowIndex(null);
              setFirstClickedNewRowIndex(null)
            }}
            className={style.Overlay}
          ></div>
        <div className={style.ItemsPopup}>
          <div className={style.ItemInput}>
            <input value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}/>
          </div>
          <div className={style.ItemsPopupBody}>
            <ul>
              {itemNameList&&itemNameList.length>0&&itemNameList.map((item,index)=>{
                return(
                  <li
                  key={index}
                  onClick={() => {
                    // Check if a row was first clicked
                    if (firstClickedRowIndex !== null) {
                      handleEditChange(
                        { target: { value: item.product_name } },
                        firstClickedRowIndex,
                        "Item Name"
                      )
                      setItemNameModified((prevModified) => {
                        const newModified = [...prevModified];
                        newModified[firstClickedRowIndex] = true;
                        return newModified;
                      });
                      // Close the popup and reset the firstClickedRowIndex
                      setShowItemsName(false);
                      setFirstClickedRowIndex(null);
                    }else if(firstClickedNewRowIndex!==null){
                      if(selectedType==="sale"){
                        setNewItemValues((prevValues) => ({
                          ...prevValues,
                          [firstClickedNewRowIndex]: item.product_name,
                        }));
                      }
                      else if(selectedType==="purchase"){
                        setNewPurchaseValues((prevValues) => ({
                          ...prevValues,
                          [firstClickedNewRowIndex]: item.product_name,
                        }));
                      }
                     
                      setShowItemsName(false);
                      setFirstClickedNewRowIndex(null)
                    }
                  }}
                >
                  {item.product_name}
                </li>
                )
              })}
             
            </ul>
          </div>
        </div>
      </>
    :''
    }
    {showAccountName?
      <>
      <div
            onClick={() => {
              setShowAccountName(false);
              setFirstClickedRowIndex(null);
              setFirstClickedNewRowIndex(null)
            }}
            className={style.Overlay}
          ></div>
        <div className={style.ItemsPopup}>
          <div className={style.ItemInput}>
            <input value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}/>
          </div>
          <div className={style.ItemsPopupBody}>
            <ul>
              {accountNameList&&accountNameList.length>0&&accountNameList.map((item,index)=>{
                return(
                  <li
                  key={index}
                  onClick={() => {
                    // Check if a row was first clicked
                    if (firstClickedRowIndex !== null) {
                      handleEditChange(
                        { target: { value: item.chart_of_account_name } },
                        firstClickedRowIndex,
                        "Paidby"
                      )
                      // Close the popup and reset the firstClickedRowIndex
                      setShowAccountName(false);
                      setFirstClickedRowIndex(null);
                      setItemNameModified((prevModified) => {
                        const newModified = [...prevModified];
                        newModified[firstClickedRowIndex] = true;
                        return newModified;
                      });
                    }else if(firstClickedNewRowIndex!==null){
                      if(selectedType==="payment"){
                        setNewPaymentValues((prevValues) => ({
                          ...prevValues,
                          [firstClickedNewRowIndex]: item.chart_of_account_name,
                        }));
                      }
                      else if(selectedType==="bank"){
                        setNewBankValues((prevValues) => ({
                          ...prevValues,
                          [firstClickedNewRowIndex]: item.chart_of_account_name,
                        }));
                      }
                      else if(selectedType==="expense"){
                        setNewExpanseValues((prevValues) => ({
                          ...prevValues,
                          [firstClickedNewRowIndex]: item.chart_of_account_name,
                        }));
                      }
                      
                      setShowAccountName(false);
                      setFirstClickedNewRowIndex(null)
                    }
                  }}
                >
                  {item.chart_of_account_name}
                </li>
                )
              })}
             
            </ul>
          </div>
        </div>
      </>
    :''
    }
    {errorPopup?<>
      <div
            onClick={() => {
              setErrorPopup(false)
            }}
            className={style.Overlay}
          ></div>
          <div className={style.ErrorPopup}>
            
            <div className={style.sectionHeader}>
              <div className={style.sectionHeaderText}>{selectedType==="sale"?<h5> Sale Invoice</h5>:selectedType==="payment"?<h5>Payment Voucher</h5>:selectedType==="bank"?<h5>Bank Statement</h5>:selectedType==="purchase"?<h5>Purchase Invoice</h5>:selectedType==="expense"?<h5>Expense </h5>:''}</div>
              <div className={style.invoiceClose}>
              <MdClose
                onClick={() => {
                  setErrorPopup(false)
                  setSelectedImage("");
                  setItemList([]);
                  setNewRow(false);
                }}
              />
            </div>
            </div>
             <div className={style.Errorsection}>
              <div className={style.ErrorsectionTop}>
                <div className={style.imageSelected}>
                  <img src={selectedImage} alt="" />
                </div>
              </div>
              <div className={style.ErrorsectionBottom}>
                <div className={style.ErrorFileName}>
                  <p>Name:<span style={{color:"red"}}>{errorFilename}</span></p>
                </div>
                <div className={style.ErrorPopupButtons}>
              <button style={{backgroundColor:'red'}} onClick={()=>{
                setDeleteInvoice(true)
              }}>Delete</button>
              <button style={{backgroundColor:'green'}} onClick={()=>{
                setRetryPopup(true)
                setRetryStatus('Confirmation')
              }}>Retry</button>
              <button onClick={()=>{
                setErrorPopup(false)
              }}>Close</button>
            </div>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {retryPopup?
    <>
    <div
      onClick={() => {
        setRetryPopup(false);
      }}
      className={style.Overlay}
    ></div>
    <div className={style.deletePopup}>
      <div className={style.deleteClose}>
        {retryStatus==='Confirmation'?
        <MdClose
          onClick={() => {
            setRetryPopup(false);
            setRetryStatus('')
          }}
        />
        :''}
      </div>
      <div className={style.deleteSection}>
        {retryStatus==='Confirmation'?
        <>
          <div className={style.popupText}>
            <p>Are you sure want to Upload? </p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
                setRetryStatus('Uploading')
                retryUpload()
              }}
            >
              Yes
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setRetryPopup(false);
              }}
            >
              No
            </button>
          </div>
        </>
        :retryStatus==='Uploading'?
        <>
        <div className={style.loader}></div>
          <div className={style.popupText}>
              <p>Uploading.... </p>
            </div>
        </>
      :retryStatus==="Success"?
      <div className={style.popupText}>
              <p>Upload Successfully </p>
            </div>
    :''}
      </div>
    </div>
  </>:''}
    </div>
  );
};

export default Upload;
