import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Layout from "./Components/Layout";
import User from "./Components/UserManagement/User";
import Profile from "./Components/Profile/Profile";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Organization from "./Components/Organization/Organization";
import Upload from "./Components/Upload/Upload";
import UploadContent from "./Components/UploadContent/UploadContent";
import BulkUpload from "./Components/BulkUpload/BulkUpload";
import BulkContent from "./Components/BulkContent/BulkContent";
import BulkUploadSection from "./Components/BulkUploadSection/BulkUploadSection";
import Analytics from "./Components/Analytics/Analytics";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path='/user' element={<User />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/organization' element={<Organization />} />
            <Route path='/voucher' element={<Upload />} />
            <Route path='/voucher/upload' element={<UploadContent />} />
            <Route path='/bulk' element={<BulkUpload />} />
            <Route path='/bulk/upload' element={<BulkContent />} />
            <Route path='/bulk/upload/section' element={<BulkUploadSection />} />
            <Route path='/analytics' element={<Analytics />} />

          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
